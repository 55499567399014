// Chakra imports
import { Avatar, Image, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function Banner(props: {
  banner: string;
  avatar: string;
  name: string; 
  breed: string;
  weight: string;
  age: any;
  [x: string]: any;
}) {
  const { banner, avatar, name, breed, weight, age, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = 'avatarBorder.100';

  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems="center" {...rest}>
      <Image
				src={banner}
				w={{ base: '100%', '3xl': '100%' }}
				maxH={{ base: '100%', '3xl': '100%' }}
				h={{ base: '140px', md: '100%' }}
				borderRadius='20px'
			/>
      <Avatar
        mx="auto"
        src={avatar}
        h="300px"
        w="300px"
        mt="-43px"
        border="4px solid"
        //borderColor={borderColor}
        borderColor={'red'}
        borderLeftColor={borderColor}
        borderRightColor={borderColor}
        borderTopColor={borderColor}  
        borderBottomColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {breed}
      </Text>
      <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="30px" me="30px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {weight} kg
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Peso
          </Text>
        </Flex>
        <Flex mx="30px" me="30px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
             {age.years} años {(age.months > 0) ? ` y ${age.months} meses` : ''}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Edad
          </Text>
        </Flex>
        
      </Flex>
    </Card>
  );
}
