// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

import ContactProfile from 'views/chapas/detail/components/ContactProfile';

export default function Contacts(
	props: {
		name: string;
		principalOwner: any;
		secondaryOwner?: any;
		veterinary?: any;
		[x: string]: any;
	}
) {
	//Get the props
	const { name, principalOwner, secondaryOwner,veterinary } = props;

	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	return (
		<Card mb={{ base: '0px', '2xl': '20px' }}>
			<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Contactos
			</Text>
			<Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
				Puedes ver aquí los contactos de {name}.
			</Text>
			<ContactProfile mb='20px' image={principalOwner.image} ranking='1' phone={principalOwner.phone} title={principalOwner.name} />
			{(secondaryOwner ?
				<ContactProfile mb='20px' image={secondaryOwner.image} ranking='2' phone={secondaryOwner.phone} title={secondaryOwner.name} />
			: <></>
			)}
			{(veterinary ?
				<ContactProfile mb='20px' image={veterinary.image} ranking='3' phone={veterinary.phone} title={veterinary.name} />
			: <></>
			)}
			
		</Card>
	);
}
