// Chakra imports
import { Box, Button, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

// Assets
import { IoCall, IoLogoWhatsapp } from 'react-icons/io5';


export default function ContactProfile(props: {
	title: string;
	ranking: string;
	phone: string;
	image: string;
	[x: string]: any;
}) {
	const { title, ranking, phone, image, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.800';
	const bg = useColorModeValue('white', 'navy.700');
	const contactPrio :any = {'1': 'Contacto Principal', '2': 'Contacto Secundario', '3': 'Veterinario'};
	const makeCall = (phone: string) => {
		window.open(`tel:${phone}`);
	};
	const sendWhatsapp = (phone: string) => {
		window.open(`https://wa.me/${phone}`);
	}
	return (
		<Card bg={bg} {...rest} p='14px' backgroundColor={'#d2f4e4'}>
			<Flex align='center' direction={{ base: 'column', md: 'row' }}>
				<Text fontWeight='900' color={textColorSecondary} fontSize='xl' me='4px' align='center' >
						{contactPrio[ranking]}
					</Text>
				<Image h='80px' w='80px' src={image} borderRadius='8px' ml={{ base: '0', lg: '20px'}} />
				<Box mt={{ base: '10px', md: '0' }}>
					
					<Text color={textColorPrimary} fontWeight='500' fontSize='md' mb='4px' align='center'>
						{title} 
					</Text>
					<Box mb='20px' display={{ base: 'block', lg: 'grid' }} ml={{ base: '0', lg: '30px'}}>
						<Flex flexDirection='column'>
							<Button leftIcon={<IoCall/>} colorScheme="brand" variant="solid" onClick={() => makeCall(phone)}>
								Llamar ya
							</Button>
							<br/>
							<Button leftIcon={<IoLogoWhatsapp/>} colorScheme="whatsapp" variant="solid" onClick={() => sendWhatsapp(phone)} >
								Mandar Whatsapp
							</Button>

							
						</Flex>
					</Box>
				</Box>
			</Flex>
		</Card>
	);
}
