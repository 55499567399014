import { Icon } from '@chakra-ui/react';
import {
  MdHome,
} from 'react-icons/md';

// Admin Imports
//import DashboardsDefault from 'views/admin/dashboards/default';

import PlateDetails from 'views/chapas/detail';


const routes = [
  // --- Dashboards ---
  {
    name: 'Dashboards',
    path: '/id',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Main Dashboard',
        layout: '/chapas',
        path: '/:flurry_id',
        component: <PlateDetails />,
      }
    ],
  },
];

export default routes;
