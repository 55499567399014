//React Imports
import {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';

//Libraries
import { intervalToDuration } from "date-fns/intervalToDuration";

// Chakra imports
import { Box, Button, Flex, Grid, Image, Text } from '@chakra-ui/react';

// Custom components
import Banner from 'views/chapas/detail/components/Banner';
import General from 'views/chapas/detail/components/General';
import Contacts from 'views/chapas/detail/components/Contacts';

// Assets
import avatar from 'assets/img/avatars/avatar4.png';
import NftBanner3 from 'assets/img/nfts/NftBanner3.png';


const flurryQuery = `query {
	perrete (id: "FLURRY_ID"){
	  name
	  dob
	  photo{
		url
	  }
	  city
	  bio
	  principalOwner
	  principalPhone
    principalOwnerImage{
      url
    }
	  secondaryOwner
	  secondaryPhone
    secondaryOwnerImage{
      url
    }
	  bannerImage{
		url
	  }
	  alergies
	  behaviouralType
	  breed
	  chip
	  flurryType
	  weight
	}
}`;

export default function PlateDetails() {


	const params: any = useParams();
	const flurry_id: string = params['flurry_id'] || "";
	const [flurry, setFlurry]: [any, (flurry: any) => void] = useState(null);
	const [defaultMessage, setDefaultMessage]: [string, (message: string) => void] = useState("Cargando...");


	const getUserLocation = () => {

		if (navigator.geolocation) {
			// what to do if supported
			navigator.geolocation.getCurrentPosition(
				 (position) => {
					// what to do once we have the position
					const { latitude, longitude } =  position.coords;

					// Reporting Coordinates Start
					const reportCoordinatesBody = {
						id: flurry_id,
						skipNotification: false,
						coordinates: {
							latitude: latitude,
							longitude: longitude,
						}
					}

					window
					.fetch(`https://epzobx6qsl.execute-api.eu-west-1.amazonaws.com/lost`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						// send the GraphQL query
						body: JSON.stringify(reportCoordinatesBody),
					})
					.then((response) => response.json())
					.then(({ data, errors }) => {

						if (errors) {
							setDefaultMessage("Sentimos informarte de que no hemos podido reportar tu ubicación, para cualquier problema escribe a hola@castizodogs.com. Gracias");
							console.log("Error getting data");
							console.error(errors);
						} else if (!data ) {
							setDefaultMessage("Sentimos informarte de que no hemos podido reportar la ubicación de esta chapita, si crees que es un error, por favor, contacta con nosotros en hola@castizodogs.com. Gracias");
							console.error(errors);
						}else{
							// set the entire component with new data
							data.perrete.dob = new Date(data.perrete.dob);
							data.perrete.age = intervalToDuration({
								start: data.perrete.dob,
								end: new Date() 
							});
							data.perrete.behaviours = data.perrete.behaviouralType.map( (behaviour: string) => `- ${behaviour}`).join("<br/>");
							setFlurry(data.perrete);
						}
					});
					// End of reporting coordinates
					
					
				},
				(error) => {
					// display an error if we cant get the users position
					console.error('Error getting user location:', error);
				}
			);
		}
		else {
			// display an error if not supported
			console.error('Geolocation is not supported by this browser.');
		}
	}

	useEffect(() => {
		window
		  .fetch(`https://graphql.contentful.com/content/v1/spaces/vkgjukxaxtv8/`, {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			  // Authenticate the request
			  Authorization: `Bearer ${process.env.REACT_APP_CF_BEARER}`,
			},
			// send the GraphQL query
			body: JSON.stringify({ query: flurryQuery.replace("FLURRY_ID", flurry_id)}),
		  })
		  .then((response) => response.json())
		  .then(({ data, errors }) => {

			if (errors) {
				setDefaultMessage("Sentimos informarte de que no hemos localizado esa chapita, si crees que es un error, por favor, contacta con nosotros en perdidos@castizodogs.com. Gracias");
				console.log("Error getting data");
			  console.error(errors);

			}if (!data?.perrete ) {
				setDefaultMessage("Sentimos informarte de que no hemos localizado esa chapita, si crees que es un error, por favor, contacta con nosotros en perdidos@castizodogs.com. Gracias");
				console.log("ID not found");
			  console.error(errors);
			}else{
				// set the entire component with new data
				data.perrete.dob = new Date(data.perrete.dob);
				data.perrete.age = intervalToDuration({
					start: data.perrete.dob,
					end: new Date() 
				});
				data.perrete.behaviours = data.perrete.behaviouralType.map( (behaviour: string) => `- ${behaviour}`).join("\n");
				setFlurry(data.perrete);
			}
		  });

		  const notification: boolean = (process.env.REACT_APP_ENVIRONMENT === "development") ? true : false;

		  if (!document.cookie.split(";").some((item) => item.trim().startsWith("alreadyScanned="))){
			window.fetch(`https://epzobx6qsl.execute-api.eu-west-1.amazonaws.com/pageview?id=${flurry_id}&skipNotification=${notification}`).then((response) => response.json()).then((data) => {
				document.cookie = "alreadyScanned=true;max-age=3600; SameSite=Lax; Secure";
			});
		  }
		  
	  }, [flurry_id]);

	return (
		<Box pt={{ base: '10px' }} id="mainContainer" maxW={'1200px'} margin={'0 auto'}>
				{/* Main Fields */}
				<Box mb="10px">
					<Flex flexDirection='column' alignItems='center'>
						<Image src="https://www.castizodogs.com/cdn/shop/files/Castizo_a_ras.png?v=1722615204&width=270" alt='banner' />
					</Flex>
				</Box>
				{(flurry  ?
					<>

					
					<Box mb='20px' display={{ base: 'block', lg: 'grid' }}>
						<Flex flexDirection='column'>
							<Banner
								banner={flurry?.bannerImage?.url || NftBanner3}
								avatar={flurry?.photo?.url || avatar}
								name={flurry?.name || defaultMessage}
								breed={flurry?.breed || defaultMessage}
								weight={flurry?.weight || defaultMessage}
								age={flurry?.age || defaultMessage}
							/>
						</Flex>
					</Box>
					<Box mb='20px' display={{ base: 'block', lg: 'grid' }}>
						<Flex flexDirection='column'>
							<Button colorScheme='red' variant='solid' size='lg' mb='20px' w='100%' onClick={getUserLocation}>
								Me han encontado perdido
							</Button>
							
						</Flex>
					</Box>
					<Grid
						mb='20px'
						templateColumns={{
							base: '1fr',
							lg: 'repeat(2, 1fr)',
						}}
						templateRows={{
							base: '1fr',
							lg: 'repeat(2, 1fr)',					'2xl': '1fr'
						}}
						gap={{ base: '20px', xl: '20px' }}>
						<Contacts 
							name={flurry?.name} 
							principalOwner={{name: flurry?.principalOwner, phone: flurry?.principalPhone, image: flurry?.principalOwnerImage?.url}} 
							secondaryOwner={{name: flurry?.secondaryOwner, phone: flurry?.secondaryPhone, image: flurry?.secondaryOwnerImage?.url}}
						/>
						<General 
							gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }} 
							minH='365px' 
							pe='20px' 
							bio={flurry?.bio || defaultMessage}
							city={flurry?.city || defaultMessage}
							alergies={flurry?.alergies || defaultMessage}
							behaviours={flurry?.behaviours || defaultMessage}
							breed={flurry?.breed || defaultMessage}
							chip={flurry?.chip || defaultMessage}
							/>
						
					</Grid>	
					</>
			: <Text>{defaultMessage}</Text>)}
			</Box>
	);
}
