// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

import Information from 'views/admin/main/profile/overview/components/Information';

// Assets
export default function GeneralInformation(props: {bio: string, breed: string, city: string, behaviours:string, [x: string]: any }) {
	const {bio, breed, city, behaviours, chip, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.800';
	//const behaviours = behaviouralType?.map(behaviour => `- ${behaviour}`) + "";
	//(behaviouralType?.map(behaviour => `- ${behaviour}`)).join("\n")
	return (
		<Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
			<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
				Biografía e información extra
			</Text>
			<Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
				{bio}
			</Text>
			<SimpleGrid columns={2} gap='20px'>
				<Information title='Chip' value={chip} />
				<Information title='Raza' value={breed} />
				<Information title='Caracter' value={behaviours} />
				<Information title='Localidad' value={city} />
			</SimpleGrid>
		</Card>
	);
}